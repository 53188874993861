<template>
  <div class="bg-white">
    <div>
      <!-- Mobile filter dialog -->
      <TransitionRoot as="template" :show="mobileFiltersOpen">
        <Dialog
          as="div"
          class="fixed inset-0 flex z-40 lg:hidden"
          @close="mobileFiltersOpen = false"
        >
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div
              class="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto"
            >
              <div class="px-4 flex items-center justify-between">
                <h2 class="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  class="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                  @click="mobileFiltersOpen = false"
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <!-- Filters -->
              <form class="mt-4 border-t border-gray-200">
                <Disclosure
                  as="div"
                  v-for="section in filters"
                  :key="section.id"
                  class="border-t border-gray-200 px-4 py-6"
                  v-slot="{ open }"
                >
                  <h3
                    class="-mx-2 -my-3 flow-root"
                    v-if="section.type === 'checkbox'"
                  >
                    <DisclosureButton
                      class="px-2 py-3 bg-white w-full flex items-center justify-between text-gray-400 hover:text-gray-500"
                    >
                      <span class="font-medium text-gray-900">
                        {{ section.name }}
                      </span>
                      <span class="ml-6 flex items-center">
                        <PlusSmIcon
                          v-if="!open"
                          class="h-5 w-5"
                          aria-hidden="true"
                        />
                        <MinusSmIcon
                          v-else
                          class="h-5 w-5"
                          aria-hidden="true"
                        />
                      </span>
                    </DisclosureButton>
                  </h3>
                  <h3
                    class="-mx-2 -my-3 flow-root"
                    v-if="section.type === 'range'"
                  >
                    <DisclosureButton
                      class="px-2 py-3 bg-white w-full flex items-center justify-between text-gray-400 hover:text-gray-500"
                    >
                      <span class="font-medium text-gray-900">
                        {{ section.name }}
                      </span>
                      <span class="ml-6 flex items-center">
                        <PlusSmIcon
                          v-if="!open"
                          class="h-5 w-5"
                          aria-hidden="true"
                        />
                        <MinusSmIcon
                          v-else
                          class="h-5 w-5"
                          aria-hidden="true"
                        />
                      </span>
                    </DisclosureButton>
                  </h3>

                  <DisclosurePanel
                    class="pt-6"
                    v-if="section.type === 'checkbox'"
                  >
                    <div class="space-y-6">
                      <div
                        v-for="(option, optionIdx) in section.options"
                        :key="option.value"
                        class="flex items-center"
                      >
                        <input
                          v-if="section.type === 'checkbox'"
                          :id="`filter-mobile-${section.id}-${optionIdx}`"
                          :name="`${section.id}[]`"
                          :value="`${section.id}.${option.value}`"
                          v-model="filterValue"
                          :type="section.type"
                          :checked="option.checked"
                          class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                          v-if="section.type === 'checkbox'"
                          :for="`filter-mobile-${section.id}-${optionIdx}`"
                          class="ml-3 min-w-0 flex-1 text-gray-500"
                        >
                          {{ option.label }}
                        </label>
                      </div>
                    </div>
                  </DisclosurePanel>
                  <DisclosurePanel class="pt-6" v-if="section.type === 'range'">
                    <div class="space-y-6 mx-2 mt-4">
                      <SliderUI
                        :min="section.options[0].minValue"
                        @update:min="sliderModel.value[0] = $event"
                        :max="section.options[0].maxValue"
                        @priceRange-update="PriceRangeUpdate($event)"
                        :currencySign="'$ '"
                        :decimals="0"
                      ></SliderUI>
                    </div>
                  </DisclosurePanel>
                </Disclosure>
              </form>
            </div>
          </TransitionChild>
        </Dialog>
      </TransitionRoot>

      <main class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div
          class="relative z-5 flex items-baseline justify-between pt-2 pb-6 border-b border-gray-200"
        >
          <div
            class="text-4xl font-extrabold tracking-tight text-gray-900"
          ></div>

          <div class="flex items-center z-10">
            <Menu as="div" class="relative inline-block text-left">
              <div>
                <MenuButton
                  class="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
                >
                  Sort
                  <ChevronDownIcon
                    class="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div class="py-1">
                    <MenuItem
                      v-for="option in sortOptions"
                      :key="option.name"
                      v-slot="{ active }"
                    >
                      <button
                        :class="[
                          option.current
                            ? 'font-medium text-gray-900'
                            : 'text-gray-500',
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm',
                        ]"
                        @click="sortValue = option.sortName"
                      >
                        {{ option.name }}
                      </button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>

            <button
              type="button"
              class="p-2 -m-2 ml-4 sm:ml-6 text-gray-400 hover:text-gray-500 lg:hidden"
              @click="mobileFiltersOpen = true"
            >
              <span class="sr-only">Filters</span>
              <FilterIcon class="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        </div>

        <section aria-labelledby="products-heading" class="pt-6 pb-24">
          <h2 id="products-heading" class="sr-only">Products</h2>

          <div class="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
            <!-- Filters -->
            <form class="hidden lg:block">
              <h3 class="sr-only">Categories</h3>
              <p>Filter products</p>
              <Disclosure
                as="div"
                v-for="section in filters"
                :key="section.id"
                class="border-b border-gray-200 py-6"
                v-slot="{ open }"
              >
                <h3 class="-my-3 flow-root" v-if="section.type === 'checkbox'">
                  <DisclosureButton
                    class="py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500"
                  >
                    <span class="font-medium text-gray-900">
                      {{ section.name }}
                    </span>
                    <span class="ml-6 flex items-center">
                      <PlusSmIcon
                        v-if="!open"
                        class="h-5 w-5"
                        aria-hidden="true"
                      />
                      <MinusSmIcon v-else class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </DisclosureButton>
                </h3>
                <h3 class="-my-3 flow-root" v-if="section.type === 'range'">
                  <DisclosureButton
                    class="py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500"
                  >
                    <span class="font-medium text-gray-900">
                      {{ section.name }}
                    </span>
                    <span class="ml-6 flex items-center">
                      <PlusSmIcon
                        v-if="!open"
                        class="h-5 w-5"
                        aria-hidden="true"
                      />
                      <MinusSmIcon v-else class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </DisclosureButton>
                </h3>

                <DisclosurePanel
                  class="pt-6"
                  v-if="section.type === 'checkbox'"
                >
                  <div class="space-y-4">
                    <div
                      v-for="(option, optionIdx) in section.options"
                      :key="option.value"
                      class="flex items-center"
                    >
                      <input
                        v-if="section.type === 'checkbox'"
                        :id="`filter-${section.id}-${optionIdx}`"
                        :name="`${section.id}[]`"
                        :value="`${section.id}.${option.value}`"
                        v-model="filterValue"
                        :type="section.type"
                        :checked="option.checked"
                        class="h-4 w-4 border-gray-300 rounded text-secondary-600 focus:ring-secondary-500"
                      />
                      <label
                        v-if="section.type === 'checkbox'"
                        :for="`filter-${section.id}-${optionIdx}`"
                        class="ml-3 text-sm text-gray-600"
                      >
                        {{ option.label }}
                      </label>
                    </div>
                  </div>
                </DisclosurePanel>
                <DisclosurePanel class="pt-6" v-if="section.type === 'range'">
                  <div class="my-4">
                    <div class="space-y-4">
                      <SliderUI
                        :min="section.options[0].minValue"
                        @update:min="sliderModel.value[0] = $event"
                        :max="section.options[0].maxValue"
                        @priceRange-update="PriceRangeUpdate($event)"
                        :currencySign="'$ '"
                        :decimals="0"
                      ></SliderUI>
                    </div>
                  </div>
                </DisclosurePanel>
              </Disclosure>
            </form>

            <div class="lg:col-span-3">
              <div class="border-1 border-gray-400 rounded-lg">
                <component
                  :blok="collection"
                  :is="collection.content.component"
                  :filterParams="filterValue"
                  :priceRange="sliderModel.value"
                  :sortParams="sortValue"
                ></component>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import {
    Dialog,
    DialogOverlay,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';
  import { XIcon } from '@heroicons/vue/outline';
  import Collection from '@/components/Collection.vue';
  import SliderUI from '@/components/UI/SliderUI.vue';

  import {
    ChevronDownIcon,
    FilterIcon,
    MinusSmIcon,
    PlusSmIcon,
    ViewGridIcon,
    CheckCircleIcon,
  } from '@heroicons/vue/solid';
  const sortOptions = [
    {
      name: 'Price: Low to High',
      href: '#',
      current: false,
      sortName: 'price.asc',
    },
    {
      name: 'Price: High to Low',
      href: '#',
      current: false,
      sortName: 'price.desc',
    },
  ];
  const filters = [
    {
      id: 'color',
      name: 'Color',
      type: 'checkbox',
      options: [
        { value: 'white', label: 'White', checked: false },
        { value: 'blue', label: 'Blue', checked: true },
        { value: 'green', label: 'Green', checked: false },
        { value: 'purple', label: 'Purple', checked: false },
      ],
    },
    {
      id: 'size',
      name: 'Size',
      type: 'checkbox',
      options: [
        { value: '14', label: '14L', checked: false },
        { value: '20', label: '20L', checked: false },
        { value: '22', label: '22L', checked: true },
        { value: '40', label: '40L', checked: true },
      ],
    },
    {
      id: 'price',
      name: 'Price',
      type: 'range',
      options: [{ minValue: 0, maxValue: 100, label: 'price' }],
    },
  ];
  export default {
    created() {
      this.loadCollection();
    },
    components: {
      SliderUI,
      Collection,
      Dialog,
      DialogOverlay,
      Disclosure,
      DisclosureButton,
      DisclosurePanel,
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      TransitionChild,
      TransitionRoot,
      ChevronDownIcon,
      FilterIcon,
      MinusSmIcon,
      PlusSmIcon,
      ViewGridIcon,
      XIcon,
      CheckCircleIcon,
    },
    setup() {
      const mobileFiltersOpen = ref(false);

      const filterValue = ref([]);
      const sortValue = ref('');
      const range = ref({
        value: [20, 40],
      });
      return {
        filterValue,
        sortValue,
        range,
        sortOptions,
        filters,
        mobileFiltersOpen,
      };
    },

    methods: {
      PriceRangeUpdate(event) {
        this.sliderModel.value[0] = event[0];
        this.sliderModel.value[1] = event[1];
      },
      loadCollection() {
        const slug = `collections/${this.$route.params.collection}`;
        window.storyblok.init({
          accessToken: process.env.VUE_APP_STORYBLOK_SPACE_KEY_PREVIEW,
        });
        window.storyblok.on('change', () => {
          this.getStory(slug, 'draft');
        });
        window.storyblok.pingEditor(() => {
          if (window.storyblok.isInEditor()) {
            this.getStory(slug, 'draft');
          } else {
            this.getStory(slug, 'published');
          }
        });
      },
      getStory(slug, version) {
        this.storyapi
          .get(`cdn/stories/${slug}`, {
            version: version,
          })
          .then((response) => {
            this.collection = response.data.story;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },

    data() {
      return {
        sliderModel: {
          value: [0, 100],
        },
        collection: {
          content: {
            body: [],
          },
        },
      };
    },
  };
</script>

<style scoped src="@vueform/slider/themes/default.css">
  .priceSlider {
    --slider-connect-bg: #ef4444;
    --slider-tooltip-bg: #ef4444;
    --slider-handle-ring-color: #ef444430;
  }
</style>
